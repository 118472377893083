import { useState, useEffect } from "react";
import axios from "axios";

export default function useBaseCategory() {
  const [baseCategory, setBaseCategory] = useState([]);
  
  //get cat
  const getCategories = async () => {
    try {
      const { data } = await axios.get("/api/v1/bcategory/all");
      setBaseCategory(data?.data);
      // console.log(data.data,"dataaaa")
    } catch (error) {
      console.log(error);
    }
  };
 
  useEffect(() => {
    getCategories();
  }, []);

  return baseCategory;
}
