import React, { useState, useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { useCart } from "../context/cart";
import "../styles/ProductDetailsStyle.css";
import toast from "react-hot-toast";

const ProductDetails = () => {
  const params = useParams();
  const [nprice, setNPrice] = useState("");
  const navigate = useNavigate();
  const [cart, setCart] = useCart();
  const [product, setProduct] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [productPhotos, setProductPhotos] = useState([]);
  const [siPi, setSiPi] = useState([]);
  const [unit, setUnit] = useState("");
  const [selectedQuantity, setSelectedQuantity] = useState(siPi[1]?.quantity);

  const handleQuantityChange = (event) => {
    setSelectedQuantity(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `/api/v1/product/get-product/${params.slug}`
        );
        setProduct(data?.product);
        getSimilarProduct(data?.product?._id, data?.product?.category?._id);
        getProductAllPhoto(data?.product?._id);
        const pd = data?.product?.pricedata;
        console.log(pd, "pd");

        const pdata = pd ? JSON.parse(pd) : {};
        console.log(pdata, "pdata");
        //start
        for (const propertyName in pdata) {
          if (
            pdata.hasOwnProperty(propertyName) &&
            Array.isArray(pdata[propertyName])
          ) {
            const u = propertyName;

            console.log(u, "unit");
            setUnit(u);
          }
        }
        // end
        const qandp =
          pdata.kg ||
          pdata.gm ||
          pdata.liter ||
          pdata.ml ||
          pdata.meter ||
          pdata.cm ||
          pdata.pcs ||
          pdata.size ||
          [];
        setSiPi(qandp);
        console.log(qandp, "qandp");
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [params?.slug]);

  const getProductAllPhoto = async (productId) => {
    try {
      const { data } = await axios.get(
        `/api/v1/product/product-allphoto/${productId}`
      );
      if (data) {
        setProductPhotos(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSimilarProduct = async (productId, categoryId) => {
    try {
      const { data } = await axios.get(
        `/api/v1/product/related-product/${productId}/${categoryId}`
      );
      setRelatedProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };

  const addToCart = (product, selectedSize) => {
    if (!selectedSize) {
      // If selectedSize is not available, display a message
      toast.error("Please select a valid quantity");
      return;
    }
    console.log(unit, "uuuuuuuuuuuu");
    const updatedCart = [...cart];
    const existingProduct = updatedCart.find(
      (item) => item._id === product._id
    );

    if (existingProduct) {
      existingProduct.customQuantity += 1;
    } else {
      // Include selectedSize in the cart item
      updatedCart.push({ ...product, customQuantity: 1, selectedSize, unit });
    }

    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    toast.success("Item Added to cart");
  };

  function changeMainImage(photo) {
    const mainImage = document.getElementById("mainImage");
    mainImage.src = `data:${photo.contentType};base64,${photo?.data}`;
  }

  const selectedSize = siPi.find((item) => item.quantity === selectedQuantity);

  useEffect(() => {
    if (selectedSize) {
      setNPrice(selectedSize.price);
    }
  }, [selectedSize]);

  return (
    <Layout>
      <div className="container mt-4">
        <div className="row product-details">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="image-container square-container">
                  <img
                    src={`data:${productPhotos[0]?.contentType};base64,${productPhotos[0]?.data}`}
                    className="img-fluid main-image square-image"
                    alt="Main Product"
                    id="mainImage"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              {productPhotos.map((photo, index) => (
                <div
                  className="col-2 justify-content-center"
                  key={index}
                  onClick={() => changeMainImage(photo)}
                >
                  <div className="d-flex justify-content-center align-items-center smaller-image-container">
                    <img
                      src={`data:${photo.contentType};base64,${photo?.data}`}
                      alt={`Product ${index + 1}`}
                      className="img-fluid  smaller-image"
                      style={{
                        width: "450px",
                        height: "100px",
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6 w-product-details-info">
            <h1 className="text-center">Product Details</h1>

            <hr />
            <h6>Select Quantity:</h6>
            <select value={selectedQuantity} onChange={handleQuantityChange}>
              <option value="">Please select an option</option>
              {siPi.map((item) => (
                <option key={item.quantity} value={item.quantity}>
                  {item.quantity}
                </option>
              ))}
            </select>
            <h6>Selected quantity: {selectedQuantity}</h6>
            {nprice && selectedSize ? (
              <>
                <h6>
                  Updated Price:{" "}
                  {Math.round(nprice).toLocaleString("en-IN", {
                    style: "currency",
                    currency: "INR",
                  })}
                </h6>
                {/* Include other details related to the selected size */}
              </>
            ) : (
              <p>No pricing information available for the selected option.</p>
            )}
            <h5>Brand: {product?.brand}</h5>
            <h6>Name: {product.name}</h6>

            <h6>
              {" "}
              <span
                style={{ color: "black", fontSize: "20px" }}
                className="bold"
              >
                Features :
              </span>{" "}
              {product.feature}
            </h6>
            <h6 className="card-title card-price">
              Price:{" "}
              {Math.round(
                nprice - (nprice * product.discount) / 100
              ).toLocaleString("en-IN", {
                style: "currency",
                currency: "INR",
              })}
            </h6>
            <h6>
              Discount:{" "}
              <b style={{ color: "green" }}>{product.discount}% off</b>
            </h6>
            <h6>
              MRP: <s className="text-danger">₹{nprice}</s>
            </h6>
            {/* <h6>Category: {product?.category?.name}</h6> */}
           <>{
            product.color?  <h6>Color: {product?.color?product.color:""}</h6>:""
           }
           </>
            {/* <h6>Available quantity: {product?.quantity}</h6> */}
            <h6>Description: {product.description}</h6>
            <button
              className="btn btn-dark mt-3 text-center w-75"
              style={{
                backgroundColor: "green",
                color: "#000",
                borderRadius: "80px",
                fontWeight: "bold",
              }}
              onClick={() => {
                addToCart(product, selectedSize);
              }}
            >
              ADD TO CART
            </button>
          </div>
        </div>
        <hr />
        <div className="row similar-products">
          <h4>Similar Products ➡️</h4>
          {relatedProducts.length < 1 && (
            <p className="text-center">No Similar Products found</p>
          )}
          <div className="d-flex flex-wrap">
            {relatedProducts?.map((p) => (
              <div key={p._id} className="col-md-4 mb-4">
                <div className="card h-100">
                  <div className="d-flex flex-row">
                    <p
                      style={{
                        borderTopLeftRadius: "25px",
                        borderBottomRightRadius: "25px",
                      }}
                      className="bg-success   w-25 pt-2  pb-2  text-white text-center"
                    >
                      {p.discount}% off
                    </p>
                    {/* <h5 className="ms-auto text-secondary px-1">{p.color}</h5> */}
                  </div>
                  <a href={`/product/${p.slug}`}>
                    <img
                      src={`/api/v1/product/product-photo/${p._id}`}
                      className="card-img-top"
                      alt={p.name}
                    />
                  </a>
                  <div className="card-body">
                    <h5 className="card-title">{p.name}</h5>
                    <div className="card-name-price">
                      <h6 className="card-title card-price">
                        {Math.round(
                          p.price - (p.price * p.discount) / 100
                        ).toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })}
                      </h6>
                      <s className="text-danger">
                        {p.price.toLocaleString("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })}
                      </s>
                    </div>
                    <p className="card-text">
                      {p.description.substring(0, 60)}...
                    </p>
                    <div className="d-grid gap-2">
                      <button
                        className="btn bg-success mt-2"
                        style={{
                          backgroundColor: "#ffa502",
                          color: "#000",
                          borderRadius: "80px",
                        }}
                        onClick={() => navigate(`/product/${p.slug}`)}
                      >
                        More Details
                      </button>
                      {/* <button
                        className="btn bg-success mt-2"
                        style={{
                          backgroundColor: "#ffa502",
                          color: "#000",
                          borderRadius: "80px",
                        }}
                        onClick={() => {
                          addToCart(p);
                        }}
                      >
                        ADD TO CART
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductDetails;
