import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { TbSelect } from "react-icons/tb";
const PriceModals = ({ isOpen, onClose, onSelectUnit, onConfirm }) => {
  const [selectedUnit, setSelectedUnit] = useState("");
  const [customUnits, setCustomUnits] = useState([]);
  const [customUnitPrices, setCustomUnitPrices] = useState({});
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");

  const handleUnitSelection = () => {
    if (selectedUnit === "pcs") {
      setCustomUnits(["pcs"]);
      setCustomUnitPrices({ pcs: [] });
    } else {
      setCustomUnits([selectedUnit]);
      setCustomUnitPrices((prevCustomUnitPrices) => ({
        ...prevCustomUnitPrices,
        [selectedUnit]: [],
      }));
    }
  };

  const addCustomUnit = () => {
    setCustomUnitPrices((prevCustomUnitPrices) => ({
      ...prevCustomUnitPrices,
      [selectedUnit]: [
        ...(prevCustomUnitPrices[selectedUnit] || []),
        { quantity, price },
      ],
    }));
    setQuantity("");
    setPrice("");
  };

  const renderCustomUnitModal = () => {
    const unitPrices = customUnitPrices[selectedUnit] || [];
    const placeholder = `Enter ${selectedUnit}`;

    return (
      <Modal show={customUnits.length > 0} onHide={() => setCustomUnits([])}>
        <Modal.Header closeButton>
          <Modal.Title>Select {customUnits[0]} and Prices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {unitPrices.map((item, index) => (
              <div key={index} className="mb-3">
                <Form.Control
                  type="text"
                  placeholder={`${customUnits[0]} - ${item.quantity} ${selectedUnit} - price ${item.price}`}
                  readOnly
                />
              </div>
            ))}
            <div className="mb-3">
              <Form.Control
                type="text"
                placeholder={placeholder}
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
              <Form.Control
                type="number"
                placeholder={`Enter price for ${customUnits[0]}`}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setCustomUnits([])}>
            Close
          </Button>
          <Button variant="primary" onClick={addCustomUnit}>
            Add More
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onSelectUnit(selectedUnit, customUnitPrices);
              onClose();
              onConfirm(selectedUnit, customUnitPrices);
              setCustomUnits([]);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Select Unit 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="select"
            value={selectedUnit}
            onChange={(e) => setSelectedUnit(e.target.value)}
            className="form-select"
          >
            <option value="" disabled>click me for choose a unit </option>
            <option value="kg">killo gram</option>
            <option value="gm">gram</option>
            <option value="liter">liter</option>
            <option value="ml">milli liter</option>
            <option value="meter">meter</option>
            <option value="cm">centi meter</option>
            <option value="pcs">peices</option>
            <option value="size">size</option>
          </Form.Control>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUnitSelection} disabled={!selectedUnit}>
            Select
          </Button>
        </Modal.Footer>
      </Modal>

      {renderCustomUnitModal()}
    </>
  );
};

export default PriceModals;
