import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import useBaseCategory from "../../hooks/useBaseCategory";
import "./Layout.css";
const HeaderCategory = () => {
  const baseCategories = useBaseCategory();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (categoryId) => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/api/v1/category/get-category/${categoryId}`
      );
      setCategories(data.data || []);
    } catch (error) {
      console.error("Error fetching category details:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSubcategories = async (subcategoryId) => {
    try {
      const { data } = await axios.get(
        `/api/v1/subcategory/get-sub-category/${subcategoryId}`
      );
      setSubCategories(data?.data || []);
    } catch (error) {
      console.log("Error fetching subcategories:", error);
    }
  };

  const handleCategoryClick = async (baseId) => {
    fetchData(baseId);
    // Reset subcategories when clicking on a category to prevent showing previous subcategories
    setSubCategories([]);
  };

  const handleSubcategoryClick = async (catId) => {
    fetchSubcategories(catId);
  };

  useEffect(() => {
    if (baseCategories && baseCategories.length > 0) {
      fetchData(baseCategories[0]._id);
    }
  }, [baseCategories]);

  return (
    <>
         <div className="mobile-container">
        <div className="mt-4">
          <div
            style={{ backgroundColor: "#FFFFFF" }}
            className="container-fluid pt-1 mt-1"
          >
            <ul className="navbar-nav justify-content-center mt-5 pt-2 gap-3 d-flex flex-row text-bottom  flex-wrap">
              {baseCategories?.map((b, index) => (
                <li
                  key={b.slug}
                  className={`nav-item ${index !== 0 ? "mr-5" : ""} ${
                    index % 1 === 1 ? "mt-3" : ""
                  } ${baseCategories.length > 2 ? "col-lg-4" : "col-lg-6"}`} // Adjust the column width based on the number of categories
                  style={{ width: "150px" }}
                >
                  <div style={{}} className="dropdown">
                    <Link
                      className="nav-link dropdown-toggle text-center"
                      to={`/bcategory/${b._id}`}
                      style={{
                        color: "#2d3436",
                        paddingLeft: "10px",
                        paddingTop: "35px",
                        gap: "12px",
                      }}
                      data-bs-toggle="dropdown"
                      onClick={() => handleCategoryClick(b._id)}
                    >
                      {
                        <img
                          src={`/api/v1/bcategory/single-photo/${b._id}`}
                          className=" "
                          height="50px"
                          alt={""}
                        />
                      }
                      <br />
                      <b>{b.name}</b>
                    </Link>
                    <ul
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: "50px",
                        zIndex: 1000,
                        marginLeft: "76px",
                      }}
                      className="dropdown-menu ml-5"
                    >
                      {loading ? (
                        <li>Loading...</li>
                      ) : Array.isArray(categories) && categories.length > 0 ? (
                        categories.map((category) => (
                          <li
                            key={category._id}
                            style={{ position: "relative" }}
                          >
                            <div className="dropdown">
                              <Link
                                className="dropdown-item text-center"
                                to={`/category/${category._id}`}
                                onClick={() =>
                                  handleSubcategoryClick(category._id)
                                }
                              >
                                {category.name}
                              </Link>
                              {subcategories.length > 0 &&
                                subcategories.some(
                                  (subcategory) =>
                                    subcategory.parentCategoryId ===
                                    category._id
                                ) && (
                                  <ul
                                    className="dropdown-menu"
                                    style={{
                                      position: "  ",
                                      top: "100%",
                                      left: 0,
                                      zIndex: 1000,
                                      marginLeft: "50px",
                                    }}
                                  >
                                    {subcategories.map((subcategory) => (
                                      <li key={subcategory._id}>
                                        <Link
                                          className="dropdown-item"
                                          to={`/subcategory/${subcategory._id}`}
                                        >
                                          {subcategory.name}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>No categories available</li>
                      )}
                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderCategory;
