import React from "react";
import { useSearch } from "../../context/search";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const SearchInput = () => {
  const [values, setValues] = useSearch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(
        `/api/v1/product/search/${values.keyword}`
      );
      setValues({ ...values, results: data });
      navigate("/search");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container-fluid">
      <form
        className="d-flex flex-column flex-sm-row "
        role="search"
        onSubmit={handleSubmit}
      >
        <input
          className="form-control col-sm-2 col-md"
          type="search"
          placeholder="search for color, name brand name"
          aria-label="Search"
          value={values.keyword}
          onChange={(e) => setValues({ ...values, keyword: e.target.value })}
        />
        <div className="d-flex  mx-1">
          <button
            className="btn btn-outline-primary mt-2 mt-sm-0"
            type="submit"
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchInput;


// import React, { useState, useEffect } from "react";
// import { useSearch } from "../../context/search";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import Typewriter from 'typewriter-effect';

// const SearchInput = () => {
//   const [values, setValues] = useSearch();
//   const navigate = useNavigate();
//   const [typewriterText, setTypewriterText] = useState("Search for color, name");
//   const [isTyping, setIsTyping] = useState(false);

//   const handleInputChange = (e) => {
//     setValues({ ...values, keyword: e.target.value });
//     setTypewriterText(e.target.value || "Search for color, name");
//   };

//   const handleInputFocus = () => {
//     setIsTyping(true);
//   };

//   const handleInputBlur = () => {
//     setIsTyping(false);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const { data } = await axios.get(
//         `/api/v1/product/search/${values.keyword}`
//       );
//       setValues({ ...values, results: data });
//       navigate("/search");
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <div className="container-fluid">
//       {isTyping ? (
//         <input
//           className="form-control col-sm-2 col-md"
//           type="search"
//           placeholder="Search for color, name"
//           aria-label="Search"
//           value={values.keyword}
//           onChange={handleInputChange}
//           onFocus={handleInputFocus}
//           onBlur={handleInputBlur}
//         />
//       ) : (
//         <Typewriter
//           options={{
//             strings: [typewriterText],
//             autoStart: true,
//             loop: true,
//           }}
//         />
//       )}
//       <div className="d-flex  mx-1">
//         <button
//           className="btn btn-outline-primary mt-2 mt-sm-0"
//           type="submit"
//           onClick={handleSubmit}
//         >
//           Search
//         </button>
//       </div>
//     </div>
//   );
// };

// export default SearchInput;
